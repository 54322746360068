import React, { useState, useEffect, useRef, useContext } from "react"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { useMedia } from "react-use"
import classnames from "classnames"
import { format } from "date-fns"
import { graphql } from "gatsby"

/* Import Global Contexts */
import UserContext from "~context/user"

/* Import Global Components */
import Page from "~components/page"
import Authors from "~components/authors"

/* Import Local Components */
import Swiper from "./components/swiper"
import Video from "./components/video"
import Player from "./components/player"

/* Import Local Styles */
import "./sketchbook-abbey-pusz.scss"

/* Import Local Movie Assets */
// import VideoOneSrc from "./assets/1.mp4"
// import VideoTwoSrc from "./assets/2.mp4"
// import VideoThreeSrc from "./assets/3.mp4"
// import VideoFourSrc from "./assets/4.mp4"
// import VideoFiveSrc from "./assets/5.mp4"
// import VideoSixSrc from "./assets/6.mp4"
// import VideoSevenSrc from "./assets/7.mp4"
// import VideoEightSrc from "./assets/8.mp4"

const videos = [
  {
    src:
      "https://stream.mux.com/PFMT0101lMIHFJrKTFxGxzo00p00jpu019SBNpk00MOPDhkBc.m3u8",
    thumbnail:
      "https://image.mux.com/PFMT0101lMIHFJrKTFxGxzo00p00jpu019SBNpk00MOPDhkBc/thumbnail.png",
    caption:
      "1. Macarena Realistic; March 2021, single channel video, runtime: 0:25 minutes.",
  },
  {
    src:
      "https://stream.mux.com/eVzhKOVsJzqAaDwxpG01uQO202hKmVJSeZCKZZmWLs013w.m3u8",
    thumbnail:
      "https://image.mux.com/eVzhKOVsJzqAaDwxpG01uQO202hKmVJSeZCKZZmWLs013w/thumbnail.png",
    caption:
      "2. Direct Action; February 2021, single channel video, runtime: 1:11 minutes.",
  },
  {
    src:
      "https://stream.mux.com/Z9i00KohWSSwyJvJr4FQAGHJMj8CZYvmB6bHKXMod3gA.m3u8",
    thumbnail:
      "https://image.mux.com/Z9i00KohWSSwyJvJr4FQAGHJMj8CZYvmB6bHKXMod3gA/thumbnail.png",
    caption:
      "3. Hearthing Cowgirl When; July 2021, single channel video; runtime: 0:11 minutes.",
  },
  {
    src:
      "https://stream.mux.com/mvxMRyMy1O19hTQjUThvi4fr2ok9KRY02oDMfhOXNkM00.m3u8",
    thumbnail:
      "https://image.mux.com/mvxMRyMy1O19hTQjUThvi4fr2ok9KRY02oDMfhOXNkM00/thumbnail.png",
    caption:
      "4. Done Later; January 2021, single channel video, runtime: 0:48 minutes.",
  },
  {
    src:
      "https://stream.mux.com/COV5UnE3RMLnez7EaS8BZgNFs5XQ00LWazpcV58dPPlE.m3u8",
    thumbnail:
      "https://image.mux.com/COV5UnE3RMLnez7EaS8BZgNFs5XQ00LWazpcV58dPPlE/thumbnail.png",
    caption:
      "5. There is a Cowboy; January 2021, single channel video, runtime: 0:41 minutes.",
  },
  {
    src:
      "https://stream.mux.com/eQk7JnWUrtMP01rI01kkU4wJMyVnDfmARI3RqGBPbeiDw.m3u8",
    thumbnail:
      "https://image.mux.com/eQk7JnWUrtMP01rI01kkU4wJMyVnDfmARI3RqGBPbeiDw/thumbnail.png",
    caption:
      "6. My First Art Show; May 2021, single channel video, runtime: 0:48 minutes.",
  },
  {
    src:
      "https://stream.mux.com/a23zW5qYrOlwSWuK6UkiLbO02J1CARTTI01HbQa5gH4QE.m3u8",
    thumbnail:
      "https://image.mux.com/a23zW5qYrOlwSWuK6UkiLbO02J1CARTTI01HbQa5gH4QE/thumbnail.png",
    caption:
      "7. Me as an Influencer; February 2021, single channel video, runtime: 0:15 minutes.",
  },
  {
    src:
      "https://stream.mux.com/gi5xLud7BStnuqOolxo9CJAPMrVQlfy9pxfqHAyOTHM.m3u8",
    thumbnail:
      "https://image.mux.com/gi5xLud7BStnuqOolxo9CJAPMrVQlfy9pxfqHAyOTHM/thumbnail.png",
    caption:
      "8. 2070 Whale Shift; July 2021, single channel video, runtime: 0:48 minutes.",
  },
]

const OverrideTemplate = ({ data: { sanityPost: post } }) => {
  const isMobile = useMedia("(max-width: 768px)")
  const [isPostSaved, setIsPostSaved] = useState(false)
  const [user, { savePost, removePost }] = useContext(UserContext)
  const videoRefOne = useRef(null)

  useEffect(() => {
    if (user && user.research) {
      const isPostSaved =
        find(user.research.posts, savedPost => {
          return savedPost.id === post.id
        }) !== undefined
      setIsPostSaved(isPostSaved)
    }
  }, [post, user])

  const handleSavePostClick = () => {
    if (user) {
      savePost(post)
    }
  }

  const handleRemovePostClick = () => {
    if (user) {
      removePost(post)
    }
  }

  const imageData = post.heroImage
    ? getGatsbyImageData(
        post.heroImage.asset.id,
        {
          maxWidth: 1200,
          maxHeight: 630,
          toFormat: "png",
        },
        { projectId: "2pq7mrv0", dataset: "production" }
      )
    : null

  return (
    <Page
      title={post.postTitle}
      className={"sketchbook-abbey-pusz"}
      description={post.postExcerpt}
      article={true}
      authors={post.postAuthor}
      datePublished={post.publicationDate}
      image={imageData ? imageData.images.fallback.src : null}
    >
      {/* <div>
        <p className="post-title">{post.postTitle}</p>
        <p>
          Posted by <Authors authors={post.postAuthor} /> on{" "}
          {format(new Date(post.publicationDate), "yyyy-MM-dd")}
        </p>
      </div>
      <div>
        {isPostSaved ? (
          <button onClick={handleRemovePostClick}>Remove Post</button>
        ) : (
          <button onClick={handleSavePostClick}>Save Post</button>
        )}
      </div> */}
      {isMobile ? (
        <div className="videos">
          {videos.map(({ src, caption, thumbnail }) => (
            <Video
              src={src}
              caption={caption}
              thumbnail={thumbnail}
              key={src}
            />
          ))}
        </div>
      ) : (
        <Swiper>
          {videos.map(({ src, caption, thumbnail }) => (
            <Video
              src={src}
              caption={caption}
              thumbnail={thumbnail}
              key={src}
            />
          ))}
        </Swiper>
      )}
      <div className="writing">
        <h1 className="title">Artist's Statement</h1>
        <p>
          Sketchbook is a series of eight vertical video art pieces. These works
          explore the often contradictory spiritual desires of today, while
          foregrounding the radical political demands for a postcapitalist or
          post-work future. Like the studies done in one’s physical sketchbook,
          they are meant to capture quick digital inspiration. First presented
          as Instagram stories, these works often include some form of
          promotional bent for DoNotResearch.net or a physical exhibition by the
          artist.
        </p>
      </div>
    </Page>
  )
}

export default OverrideTemplate

export const query = graphql`
  query SketchbookAbbeyPusz {
    sanityPost(id: { eq: "-469dd32b-11e8-5487-9a1b-0887da445c65" }) {
      id
      _id
      slug
      postTitle
      postAuthor {
        _id
        name
        slug
        publishOnWebsite
      }
      publicationDate
      postSeries {
        _id
        title
        slug
      }
      _rawPostContent(resolveReferences: { maxDepth: 10 })
      _rawPostSummary
      postExcerpt
      reports {
        _id
        slug
        publishOnWebsite
        title
      }
      heroImage {
        asset {
          id
        }
      }
    }
  }
`
